import styled from 'styled-components'
import { Box } from '@veracity/vui'
import useResultFilters from './useResultFilters'
import './ResultTable.css'
import ComparisonTable from './ComparisonTable'

import MultiCountriesTable from './MultiCountriesTable'
import RateAntd from './RateAntd'

const TableWrapper = styled(Box)`
  .category {
    font-weight: 600;
    font-size: 16px;
  }
`

const ResultTable = () => {
  const { technologyId, items } = useResultFilters()
  const countriesIds = items.map(x => x.countryId)
  const isMultipleCountries = countriesIds.length > 2

  return (
    <>
      <TableWrapper column px={4}>
        {isMultipleCountries ? (
          <MultiCountriesTable />
        ) : (
          <ComparisonTable ofCountriesNum={countriesIds.length} />
        )}
      </TableWrapper>
      <Box column p={4} alignItems="center">
        <RateAntd countriesIds={countriesIds} technologyId={technologyId} />
      </Box>
    </>
  )
}

export default ResultTable
