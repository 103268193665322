import { Badge, Box, P } from '@veracity/vui'
import { DocumentsApplicableLevels, DocumentSection } from '../../api/search'
import { DocumentSectionLink } from '../../components/DocumentSectionLink'

export interface DocumentsTableCellProps {
  areDocumentsApplicable: DocumentsApplicableLevels
  areDocumentsApplicableComment: string
  documentSections: DocumentSection[]
}

export const DocumentsTableCell = ({
  documentSections,
  areDocumentsApplicable,
  areDocumentsApplicableComment
}: DocumentsTableCellProps) => {
  // const getIconType = (type: DocumentsApplicableLevels) => {
  //   switch (type) {
  //     case DocumentsApplicableLevels.Yes:
  //       return 'falCheck'
  //     case DocumentsApplicableLevels.No:
  //       return 'falTimes'
  //     default:
  //       return ''
  //   }
  // }

  return (
    <Box gap={1}>
      {areDocumentsApplicable === DocumentsApplicableLevels.No && (
        <Badge iconLeft="falTimes" text="Not applicable" variant="subtleRed" />
      )}
      <Box column>
        {documentSections?.map((docSection, index) => (
          <DocumentSectionLink documentSection={docSection} key={index} />
        ))}
        {areDocumentsApplicableComment && (
          <P size="sm">{areDocumentsApplicableComment}</P>
        )}
      </Box>
    </Box>
  )
}
