import { useState, useCallback } from 'react'
import { Outlet } from 'react-router-dom'
import ResizeWatcher, { OnResizeCallback } from '../ResizeWatcher'
import Footer from './footer'
import Header from './header'

export const BaseLayout = () => {
  const [headerHeight, setHeaderHeight] = useState(0)
  const [footerHeight, setFooterHeight] = useState(0)

  const nonContentHeight = headerHeight + footerHeight

  const handleHeaderResize = useCallback<OnResizeCallback>(({ height }) => {
    if (height) {
      setHeaderHeight(height)
    }
  }, [])

  const handleFooterResize = useCallback<OnResizeCallback>(({ height }) => {
    if (height) {
      setFooterHeight(height)
    }
  }, [])

  return (
    <main>
      <ResizeWatcher onResize={handleHeaderResize}>
        <Header />
      </ResizeWatcher>
      <article
        style={{
          minHeight: `calc(100vh - ${nonContentHeight}px)`,
          position: 'relative'
        }}>
        <Outlet />
      </article>
      <ResizeWatcher onResize={handleFooterResize}>
        <Footer />
      </ResizeWatcher>
    </main>
  )
}
