import { Badge, Box } from '@veracity/vui'
import { DocumentsApplicableLevels, DocumentSection } from '../../api/search'
import { DocumentSectionLink } from '../../components/DocumentSectionLink'

export interface DocumentsTableCellProps {
  areDocumentsApplicable: DocumentsApplicableLevels
  areDocumentsApplicableComment: string
  documentSection: DocumentSection
}

export const DocumentTableCell = ({
  documentSection,
  areDocumentsApplicable
}: DocumentsTableCellProps) => {
  return (
    <Box gap={1}>
      {areDocumentsApplicable === DocumentsApplicableLevels.No && (
        <Badge iconLeft="falTimes" text="Not applicable" variant="subtleRed" />
      )}
      <Box column>
        <DocumentSectionLink documentSection={documentSection} />
        {/* {areDocumentsApplicableComment && (
                    <P size="sm">{areDocumentsApplicableComment}</P>
                )} */}
      </Box>
    </Box>
  )
}
