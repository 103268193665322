import { Box, Button, Card, Heading, List, ListItem, P } from '@veracity/vui'
import BgContainer from '../../../components/styled/BgContainer'
import CenterAbsolute from '../../../components/styled/CenterAbsolute'
import { UserRoles } from '../../../store/slices/user'

interface LoginBoxProps {
  isLoggedAs: UserRoles
}
const LoginBox = ({ isLoggedAs }: LoginBoxProps) => (
  <Box column w="100%">
    <BgContainer fullScreen>
      <CenterAbsolute>
        <Card
          align="center"
          py={5}
          px={8}
          position="relative"
          column
          w={790}
          bg="rgba(255, 255, 255, 0.85)">
          <Box>
            <Heading
              text="Welcome to "
              size="title"
              fontSize={64}
              color="#0F204B"
              mr={1}
            />
            <Heading text="DiGCCe" size="title" fontSize={64} color="#21ACDE" />
          </Box>
          {isLoggedAs !== undefined && isLoggedAs < 0 && (
            <Heading
              text="You Don't Have Permission To Access on This Site"
              size="lead"
              m={1}
              mb={0}
              fontSize={24}
            />
          )}
          {isLoggedAs === undefined && (
            <Box gap={4} pt={3}>
              <a href="/account/signin">
                <Button size="lg">Sign in</Button>
              </a>
            </Box>
          )}
        </Card>
      </CenterAbsolute>
    </BgContainer>

    <Box py={14} w={790} mx="auto" display="block">
      <Heading size="h2">A little About DiGCCe</Heading>

      <P size="md" mt={3}>
        Digital Grid Code Compliance engine, DiGCCe, is a tool developed by DNV
        that provides overview and comparison of the grid code requirements and
        compliance processes in different countries. The main features of the
        tool (for now) are:
      </P>
      <Box mt={3} gap={6}>
        <Box w="45%" column bg="#E9F7FC" p={2}>
          <Heading size="h5" color="#001C4D" mb="0.5em">
            Country summaries
          </Heading>
          <P size="sm" color="#1A1A19">
            providing a short summary of the relevant regulations and compliance
            processes, including links to relevant documentation. Including also
            international grid codes, such as RfG and EN50549.
          </P>
        </Box>
        <Box w="45%" column bg="#E9F7FC" p={2}>
          <Heading size="h5" color="#001C4D" mb="0.5em">
            Search function
          </Heading>
          <P size="sm" color="#1A1A19">
            for finding references, key characteristics and compliance rules per
            grid code requirement.
          </P>
        </Box>
      </Box>

      <Box mt={3} column>
        <Heading size="h6" mb="0.5em">
          This search can be done:
        </Heading>

        <List size="md" my={1} listStyleType="circle" ml={3}>
          <ListItem display="list-item" h="auto" pl={1} lineHeight="21px">
            <u>Per country,</u> listing information for all applicable
            requirements for a specific technology (i.e synchronous power
            generator, power park modules and storage units) and type (as
            specified for that country). It is also possible to search for
            international grid codes, such as RfG and EN50549).
          </ListItem>
          <ListItem
            display="list-item"
            h="auto"
            mt={1}
            pl={1}
            lineHeight="21px">
            <u>Side by side comparison</u> of two different search entries,
            including all applicable requirements.
          </ListItem>
          <ListItem
            display="list-item"
            h="auto"
            mt={1}
            pl={1}
            lineHeight="21px">
            <u>Per requirement,</u> listing information for an unlimited number
            of countries (or international standards) for a specific requirement
            and technology
          </ListItem>
        </List>
      </Box>
    </Box>
  </Box>
)

export default LoginBox
