import { Table } from '@veracity/vui'
import { tryParse as tryParseJson } from '../utils/jsonUtils'

export interface KeyCharacteristicJsonSchema {
  id: string
  label: string
  frontOfficeLabel: string
}

export interface KeyCharacteristicTablePreviewProps {
  jsonStringifiedData: string
  jsonSchema: string
}

export const KeyCharacteristicTablePreview = ({
  jsonStringifiedData,
  jsonSchema
}: KeyCharacteristicTablePreviewProps) => {
  const _jsonSchema: KeyCharacteristicJsonSchema[] =
    tryParseJson(jsonSchema) || []
  const _jsonData = tryParseJson(jsonStringifiedData) || []

  return (
    <Table
      bg={'white'}
      columns={_jsonSchema.map(({ id, label, frontOfficeLabel }) => {
        return { field: id, title: frontOfficeLabel || label }
      })}
      rows={_jsonData}
    />
  )
}
