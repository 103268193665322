import styled from 'styled-components'

const AccStylesOverride = styled.div`
  .vui-accordion-item .vui-box:first-child {
    font-weight: 600;
  }
  .vui-accordion-item.acc--is-open {
    background: #f2fafd;
    border-left: 3px solid #4dbde5;
    .vui-linkIcon {
      width: 1.4em;
      height: 1.4em;
      margin-bottom: -0.4em;
    }
    .vui-box:last-child {
      margin: 0 0 24px;
      font-size: 14px;
      line-height: 22px;
    }
  }
`

export default AccStylesOverride
