import { Box } from '@veracity/vui'
import { KeyCharacteristic, KeyCharacteristicTypes } from '../../api/search'
import { KeyCharacteristicsInnerTable } from './KeyCharacteristicsInnerTable'

interface MultiCountriesExpandedRowProps {
  record: {
    countryRequirementSummaries: string[]
    condensedSummaries: string[]
    keyCharacteristics: KeyCharacteristic[]
  }
  headerKey: string
  keyCharacteristicTypes: KeyCharacteristicTypes[]
}
const MultiCountriesExpandedRow = ({
  record,
  headerKey,
  keyCharacteristicTypes
}: MultiCountriesExpandedRowProps) => {
  const width =
    document.querySelector(`.header-${headerKey}`)?.clientWidth ?? 'auto'

  const _condensedSummaries = [
    ...record.countryRequirementSummaries,
    ...record.condensedSummaries
  ].filter(summary => !!summary)
  const hasSummaries = _condensedSummaries?.length > 0

  return (
    <>
      {hasSummaries && (
        <table className="inner-table">
          <tbody>
            <tr>
              <td valign="top" className="inner-table-header inner-table-title">
                Condensed summary
              </td>
              <td width={width}>
                <Box column gap={1}>
                  {_condensedSummaries.map((summary, index) => (
                    <Box key={`summary--${index}`} bg="seaBlue.95" p={1}>
                      {summary}
                    </Box>
                  ))}
                </Box>
              </td>
            </tr>
          </tbody>
        </table>
      )}

      {record.keyCharacteristics.length > 0 && (
        <KeyCharacteristicsInnerTable
          keyCharacteristicsTypes={keyCharacteristicTypes}
          keyCharacteristicsCountries={[record.keyCharacteristics]}
        />
      )}
    </>
  )
}

export default MultiCountriesExpandedRow
