import BaseTable from '../../components/BaseTable'
import { Box, Button, Icon } from '@veracity/vui'
import useResultFilters from './useResultFilters'
import {
  ComplianceLevel,
  DocumentsApplicableLevels,
  DocumentSection,
  search,
  SearchFormParams,
  SearchResultResponse
} from '../../api/search'
import { useEffect, useMemo, useState } from 'react'
import * as R from 'ramda'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { DictionaryEntry } from '../../store/slices/dictionaries'
import ComparisonExpandedRow from './ComparisonExpandedRow'
import { PopoverCountryDocuments } from './PopoverCountryDocuments'
import { DocumentsTableCell } from './DocumentsTableCell'
import { AddNewColumnModal } from '../../components/AddNewColumnModal'

const ComparisonTable = ({ ofCountriesNum }: { ofCountriesNum: number }) => {
  const queryParams = useResultFilters()

  const [isAddColumnModalOpen, setIsAddColumnModalOpen] = useState<boolean>(false);
  const countriesDictionary = useSelector<RootState, DictionaryEntry[]>(
    state => state.dictionaries.countries
  )
  const rfgTypesDictionary = useSelector<RootState, DictionaryEntry[]>(
    state => state.dictionaries.rfgClassificationTypes
  )
  const [searchResult, setSearchResult] = useState<SearchResultResponse>({
    results: [],
    headers: []
  })
  const dataSource = useMemo(() => {
    let rows = []
    for (let dataRow of searchResult.results) {
      const values = dataRow.countryRequirementMatrices.reduce(
        (
          acc,
          {
            countryId,
            rfgClassificationTypeId,
            documentSections,
            compliances,
            keyCharacteristics,
            countryRequirementSummaries,
            condensedSummaries,
            areDocumentsApplicable,
            areDocumentsApplicableComment
          }
        ) => {
          const key = `${countryId}-${rfgClassificationTypeId}`
          acc[key] = {
            coveredBy: {
              areDocumentsApplicable,
              areDocumentsApplicableComment,
              documentSections
            },
            pocUnitLevel: compliances?.filter(
              compliance => compliance.level === ComplianceLevel.Unit
            ),
            pocProjectLevel: compliances?.filter(
              compliance => compliance.level === ComplianceLevel.Project
            ),
            countryRequirementSummaries,
            condensedSummaries,
            areDocumentsApplicable,
            keyCharacteristics,
            hasNothingToShow:
              condensedSummaries.length === 0 &&
              countryRequirementSummaries.length === 0 &&
              keyCharacteristics.length === 0
          }
          return acc
        },
        {} as Record<string, any>
      )
      let hasNothingToShow = Object.values(values)[0].hasNothingToShow
      if (Object.values(values)[1] && hasNothingToShow)
        hasNothingToShow = Object.values(values)[1].hasNothingToShow

      rows.push({
        key: dataRow.requirementId,
        requirementCategory: dataRow.requirementCategory,
        name: dataRow.requirementName,
        types: dataRow.keyCharacteristicTypes,
        values,
        hasNothingToShow
      })
    }

    const groupedByCategories = R.groupBy(i => i.requirementCategory, rows)

    rows = Object.keys(groupedByCategories).reduce((result, key) => {
      const categoryData = groupedByCategories[key]
      result.push({ key, name: key, isCategory: true })
      result.push(...categoryData)

      return result
    }, [] as any[])

    return rows
  }, [searchResult.results])

  const removeColumn = (typeId: string) => {
    setSearchResultLoading(true)
    queryParams.items = queryParams.items.filter(item => item.rfgClassificationTypeId !== typeId)
    search({ ...queryParams }).then(result => {
      setSearchResult(result)
      setSearchResultLoading(false)
    })
  }

  const columns = useMemo(
    () => [
      {
        title: 'Requirement',
        dataIndex: 'name',
        key: 'name',
        width: '20%'
      },
      ...searchResult.headers.map(
        ({ countryId, rfgClassificationTypeId, countryDocuments }) => {
          const key: string = `${countryId}-${rfgClassificationTypeId}`
          const countryName = countriesDictionary.find(
            ({ id }) => countryId === id
          )?.name
          const rfgTypeName: string | undefined = rfgTypesDictionary.find(
            ({ id }) => rfgClassificationTypeId === id
          )?.name
          const title = <Box>{countryName}, {rfgTypeName} {searchResult.headers.length > 1 && <Button
            iconLeft="falTimesCircle"
            onClick={() => removeColumn(rfgClassificationTypeId)}
            size="sm"
            minW={40}
            p={0}
            m={0}
            variant="tertiaryDark"
          />}</Box>
          return {
            title,
            className: `header-${key}`,
            key: `header-${key}`,
            children: [
              {
                title: (
                  <Box>
                    <Box flex={6}>Covered By</Box>
                    <Box flex={1} center>
                      <PopoverCountryDocuments
                        countryDocuments={countryDocuments}
                      />
                    </Box>
                  </Box>
                ),
                width: 'auto',
                dataIndex: ['values', key, 'coveredBy'],
                key: `${key}.coveredBy`,
                render: (data: {
                  areDocumentsApplicable: DocumentsApplicableLevels
                  areDocumentsApplicableComment: string
                  documentSections: DocumentSection[]
                }) => {
                  if (data === null) return ''
                  return <DocumentsTableCell {...data} />
                }
              },
            ]
          }
        }
      )
    ],
    [searchResult.headers, countriesDictionary, rfgTypesDictionary]
  )

  const [searchResultLoading, setSearchResultLoading] = useState(true)

  const openAddColumnModal = () => {
    setIsAddColumnModalOpen(true);
  }

  const closeAddColumnModal = () => {
    setIsAddColumnModalOpen(false);
  }

  useEffect(() => {
    search({ ...queryParams }).then(result => {
      setSearchResult(result)
      setSearchResultLoading(false)
    })
  }, [queryParams])

  const getDataFromModal = (values: SearchFormParams) => {
    setSearchResultLoading(true)
    const routeValues = {
      countryId: values.items[0].countryId,
      rfgClassificationTypeId: values.items[0].rfgTypeId,
    }
    queryParams.items.push(routeValues);

    search({ ...queryParams }).then(result => {
      setSearchResult(result)
      setSearchResultLoading(false)
    })

    closeAddColumnModal();
  }

  return (<Box w={'100%'} display={'flex'}>
    <Box w={'95%'} display={'table'}>
      <BaseTable
        loading={searchResultLoading}
        dataSource={dataSource}
        columns={columns}

        expandable={{
          rowExpandable: record => !record.isCategory && !record.hasNothingToShow,
          expandedRowRender: (record, index) => (
            <ComparisonExpandedRow
              key={index}
              record={record}
              headers={searchResult.headers}
              countriesNum={ofCountriesNum || columns.length - 1}
            />
          ),
          expandRowByClick: true,
          expandedRowClassName: () => 'clear-padding',
          expandIcon: ({ expandable, expanded, onExpand, record }) => (
            <>
              {expandable && (
                <Icon
                  onClick={(event: any) => onExpand(record, event)}
                  h={12}
                  w={12}
                  name="falChevronDown"
                  rotate={expanded ? 180 : 0}
                  transition="transform .3s"
                />
              )}
            </>
          )
        }}
        rowClassName={record => (record.isCategory ? 'category' : '')}
        pagination={false}
      />
    </Box>
    <Box w={'5%'}>
      <Button
        iconLeft="falPlus"
        onClick={() => openAddColumnModal()}
        size="md"
        variant="tertiaryDark"
        minW={40}
        p={0}
        m={0}
      />
    </Box>
    {isAddColumnModalOpen && <AddNewColumnModal isOpen={isAddColumnModalOpen}
      onClose={closeAddColumnModal} onAddColumn={getDataFromModal} />}
  </Box>

  )
}

export default ComparisonTable
