import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

const useResultFilters = () => {
  const [urlSearchParams] = useSearchParams()
  const countriesIds = urlSearchParams.getAll('countryId').join('|')
  const rfgClassificationIds = urlSearchParams
    .getAll('rfgClassificationTypeId')
    .join('|')
  const technologyId = urlSearchParams.get('technologyId') || ''
  const requirementId = urlSearchParams.get('requirementId') || undefined
  const categoryId = urlSearchParams.get('categoryId') || undefined

  const params = useMemo(
    () => ({
      technologyId,
      requirementId,
      categoryId,
      items: countriesIds.split('|').map((countryId, index) => ({
        countryId,
        rfgClassificationTypeId: rfgClassificationIds.split('|')[index]
      }))
    }),
    [
      countriesIds,
      technologyId,
      rfgClassificationIds,
      requirementId,
      categoryId
    ]
  )

  return params
}

export default useResultFilters
