import { Box, SelectOptionData } from '@veracity/vui'
import { useEffect, useState } from 'react'
import { getRfgClassificationTypes, SearchFormParams } from '../../api/search'
import { DictionaryEntry } from '../../store/slices/dictionaries'
import { useLocation } from 'react-router-dom'
import FormSelect from '../../components/form/FormSelect'
import { UseFormReturn } from 'react-hook-form'

export interface RfgClassificationType extends DictionaryEntry {
  description: string
}

interface CountryTypeBoxProps {
  form: UseFormReturn<SearchFormParams>
  countryId?: string | undefined
  countryOptions: SelectOptionData[]
  arrayIndex?: number
}

const CountryTypeBox = ({
  form,
  countryOptions,
  arrayIndex = 0
}: CountryTypeBoxProps) => {
  const location = useLocation()
  const [idFromLocation, setIdFromLocation] = useState<string | null>(
    location.state ? location.state.id : null
  )

  const countryId = form.watch(`items.${arrayIndex}.countryId` as any)

  const [countryRfgTypes, setCountryRfgTypes] = useState<
    RfgClassificationType[] | []
  >([])

  useEffect(() => {
    if (idFromLocation !== null && countryId === undefined) {
      form.setValue(`items.${arrayIndex}.countryId` as any, idFromLocation)
      getRfgClassificationTypes(idFromLocation).then(data => {
        setCountryRfgTypes(data)
      })
    }
  }, [])

  useEffect(() => {
    if (countryId !== undefined) {
      getRfgClassificationTypes(countryId).then(data => {
        setCountryRfgTypes(data)
        setIdFromLocation(null)
        form.resetField(`items.${arrayIndex}.rfgTypeId` as any)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId])

  return (
    <Box borderWidth={1} borderStyle={'solid'} borderColor={'#99D9F0'} p={1}>
      <Box gap={2} column>
        <FormSelect
          name={`items.${arrayIndex}.countryId`}
          label={`International / National`}
          options={countryOptions}
          control={form.control}
          labelProps={{ w: 324 }}
        />
        <FormSelect
          name={`items.${arrayIndex}.rfgTypeId`}
          label={`Type`}
          disabled={countryId === undefined}
          options={countryRfgTypes.map(rfgType => {
            return {
              text: `${rfgType.name}: ${rfgType.description}`,
              value: rfgType.id
            }
          })}
          control={form.control}
          labelProps={{ w: 324 }}
        />
      </Box>
    </Box>
  )
}

export default CountryTypeBox
