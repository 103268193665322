import { Box, Heading, Button } from '@veracity/vui'
import { useNavigate } from 'react-router-dom'
import Container from '../../components/styled/Container'
import ResultTable from './ResultTable'
import ResultNotes from './ResultNotes'

const CountryRequirementsPage = () => {
  const navigate = useNavigate()

  return (
    <Box column>
      <Box bg="warmGrey.95" py={1}>
        <Container>
          <Button variant="secondaryDark" onClick={() => navigate('/search')}>
            Back
          </Button>
        </Container>
      </Box>
      <Container>
        <Heading size="h3" fontWeight={500} text="Results" my={1} />
      </Container>
      <Box mt={2} mb={2}>
        <Container>
          <Box display='block'>
            <p>Below follows a table showing the  GCC requirements for your search parameters.
              Note that the search can be modified by adding/deleting additional columns.
            </p>
            <p>By expanding a requirement, more information is presented,
              including references to relevant documents and how compliance is proven.</p>
          </Box>
        </Container>

      </Box>
      <ResultNotes />
      <ResultTable />
    </Box>
  )
}

export default CountryRequirementsPage
