import { Box, Spinner, useLoadLinks } from '@veracity/vui'
import { useSelector } from 'react-redux'
import AppRouter from './AppRouter'
import useUserSession from './hooks/useUserSession'
import { RootState } from './store'
import useDictionaries from './hooks/useDictionaries'

const App = () => {
  const env = 'Prod'
  const isLoggedIn = useSelector<RootState, boolean>(
    state => state.user.isLoggedIn
  )

  const sessionLoading = useUserSession()
  useLoadLinks(env, isLoggedIn)
  useDictionaries()

  if (sessionLoading)
    return (
      <Box mt={4} w="100%" center>
        <Spinner text="Loading app..." />
      </Box>
    )

  return <AppRouter />
}

export default App
