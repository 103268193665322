import { createSlice } from '@reduxjs/toolkit'

export interface DictionaryEntry {
  id: string
  name: string
  [key: string]: string
}

interface Dictionaries {
  countries: DictionaryEntry[]
  technologies: DictionaryEntry[]
  rfgClassificationTypes: DictionaryEntry[]
  requirementCategories: DictionaryEntry[]
  requirements: DictionaryEntry[]
}

interface DictionariesState extends Dictionaries {
  loading: boolean
}

const initialState: DictionariesState = {
  loading: false,
  countries: [],
  technologies: [],
  rfgClassificationTypes: [],
  requirementCategories: [],
  requirements: []
}

const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState,
  reducers: {
    setDictionaries: (state, { payload }: { payload: Dictionaries }) => {
      Object.keys(payload).forEach(
        key =>
          (state[key as keyof Dictionaries] =
            payload[key as keyof Dictionaries])
      )
    },
    setLoading: (state, { payload }) => {
      state.loading = payload.value
    }
  }
})

export const { reducer: dictionariesReducer, actions: dictionariesActions } =
  dictionariesSlice
