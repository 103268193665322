import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getDictionaries } from '../api/search'
import { dictionariesActions } from '../store/slices/dictionaries'

const useDictionaries = () => {
  const dispatch = useDispatch()

  const fetchDictionaries = useCallback(async () => {
    dispatch(dictionariesActions.setLoading(true))

    const dictionaries = await getDictionaries()
    dispatch(dictionariesActions.setDictionaries(dictionaries))

    dispatch(dictionariesActions.setLoading(false))
  }, [dispatch])

  useEffect(() => {
    fetchDictionaries()
  }, [fetchDictionaries])
}

export default useDictionaries
