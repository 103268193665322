import { Box, Button, Heading, P } from '@veracity/vui'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from '../../store'

import Container from '../../components/styled/Container'
import CountriesCards from './CountriesCards'
import { UserRoles } from '../../store/slices/user'
import LoginBox from './LoginBox'
import BgContainer from '../../components/styled/BgContainer'

const HomePage = () => {
  const isLoggedAs = useSelector<RootState, UserRoles>(
    state => UserRoles[state.user.role as keyof typeof UserRoles]
  )
  const firstName = useSelector<RootState, string | undefined>(
    state => state.user.firstName
  )
  const lastName = useSelector<RootState, string | undefined>(
    state => state.user.lastName
  )

  if (isLoggedAs >= 0) {
    return (
      <>
        <BgContainer>
          <Box
            pb={3}
            pt={19}
            px={4}
            bg="rgba(0,0,0,50%)"
            alignItems="center"
            justifyContent="center">
            <Heading size="h1" color="white">
              Welcome {firstName} {lastName}
            </Heading>
          </Box>
        </BgContainer>
        <Container column>
          <Box mt={5} justifyContent="center">
            <Link to="/search">
              <Button variant="primaryDark" size="lg" py={1} px={2}>
                Search
              </Button>
            </Link>
          </Box>

          <Box justifyContent="center" column mt={4}>
            <P size="md" textAlign="center" w="80%" mx="auto">
              Grid codes, standards and guidelines that are included and have
              been assessed by DNV are listed and presented below under either
              ”International” and ”National”. If expanding the dropdown, you
              will find a summary of each entry, links to relevant documentation
              and a note regarding the current population status of the DiGCCe
              database. To search the DiGGCe database, click Search
            </P>
          </Box>

          <CountriesCards />
        </Container>
      </>
    )
  } else return <LoginBox isLoggedAs={isLoggedAs} />
}
export default HomePage
