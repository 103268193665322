import { Box } from '@veracity/vui'
import {
  Compliance,
  ComplianceApplicableLevels,
  DocumentSection,
  DocumentsApplicableLevels,
  KeyCharacteristic
} from '../../api/search'
import { KeyCharacteristicsInnerTable } from './KeyCharacteristicsInnerTable'
import { DocumentTableCell } from './DocumentTableCell'

interface DocumentReference {
  areDocumentsApplicable: DocumentsApplicableLevels
  areDocumentsApplicableComment: string
  documentSections: DocumentSection
}

interface ComparisonExpandedRowProps {
  record: {
    values: {
      key: string
      condensedSummaries: string[]
      countryRequirementSummaries: string[]
      keyCharacteristics: KeyCharacteristic[]
      pocProjectLevel: Compliance[]
      pocUnitLevel: Compliance[]
      coveredBy: {
        areDocumentsApplicable: DocumentsApplicableLevels
        areDocumentsApplicableComment: string
        documentSections: DocumentSection[]
      }
    }[]
    types: { id: string; name: string }[]
  }
  headers: {
    countryId: string
    rfgClassificationTypeId: string
  }[]
  countriesNum?: number
}
const ComparisonExpandedRow = ({
  record,
  countriesNum
}: ComparisonExpandedRowProps) => {
  const recordValues = Object.values(record.values)

  const hasCondensedSummary =
    recordValues.find(
      record =>
        record.countryRequirementSummaries.length > 0 ||
        record.condensedSummaries.length > 0
    ) !== undefined

  const hasProofOfCompliance = recordValues.find(
    record =>
      record.pocProjectLevel.length > 0 || record.pocUnitLevel.length > 0
  )

  let pocProject: Compliance[] = []
  recordValues.map(record => pocProject.push(...record.pocProjectLevel))

  let pocUnit: Compliance[] = []
  recordValues.map(record => pocUnit.push(...record.pocUnitLevel))

  let unitReferencesDocuments: DocumentReference[] = []

  let projectReferencesDocuments: DocumentReference[] = []

  const changeType = (type: ComplianceApplicableLevels) => {
    switch (type) {
      case ComplianceApplicableLevels.Yes:
        return DocumentsApplicableLevels.Yes
      case ComplianceApplicableLevels.No:
        return DocumentsApplicableLevels.No
      case ComplianceApplicableLevels.NotAvailable:
        return DocumentsApplicableLevels.NotAvailable
    }
  }
  pocUnit.map(record => {
    if (!!record.complianceMethod && !!record.documentSection) {
      unitReferencesDocuments.push({
        areDocumentsApplicable: changeType(record.isApplicable),
        areDocumentsApplicableComment: record.comment,
        documentSections: record.documentSection
      })
    }
  })

  pocProject.map(record => {
    if (!!record.complianceMethod && !!record.documentSection) {
      projectReferencesDocuments.push({
        areDocumentsApplicable: changeType(record.isApplicable),
        areDocumentsApplicableComment: record.comment,
        documentSections: record.documentSection
      })
    }
  })

  return (
    <>
      {hasCondensedSummary && (
        <table className="inner-table">
          <tbody>
            <tr>
              <td
                data-size={recordValues.length}
                valign="top"
                width="30%"
                className="inner-table-header inner-table-title">
                Condensed summary
              </td>
              {recordValues.map((record, index) => {
                return (
                  <td
                    key={`${record.key}-${index}`}
                    width={`${80 / (countriesNum || recordValues.length)}%`}
                    valign="top">
                    <Box column gap={1}>
                      {[
                        ...record.countryRequirementSummaries,
                        ...record.condensedSummaries
                      ]
                        .filter(x => !!x)
                        .map((summaries, index) => (
                          <Box
                            bg="seaBlue.95"
                            p={1}
                            key={`condensedSummaries--${index}`}>
                            {summaries}
                          </Box>
                        )) || null}
                    </Box>
                  </td>
                )
              })}
              {countriesNum && recordValues.length < countriesNum && (
                <td width={`${80 / countriesNum}%`}></td>
              )}
            </tr>
          </tbody>
        </table>
      )}
      {hasProofOfCompliance && (
        <table className="inner-table">
          <tbody>
            <tr>
              <td
                data-size={recordValues.length}
                valign="top"
                width="15%"
                className="inner-table-header inner-table-title">
                Proof of compilance
              </td>
              <td></td>
            </tr>
            <tr>
              <td
                valign="top"
                align="right"
                className="inner-table-header"
                width="14%">
                - Unit level:
              </td>
              {recordValues.map((item, index) =>
                item.pocUnitLevel.map(unit => (
                  <td key={`unit-${index}-${unit.id}`}>{unit.comment}</td>
                ))
              )}
            </tr>
            {unitReferencesDocuments.length > 0 && (
              <tr>
                <td
                  valign="top"
                  align="right"
                  className="inner-table-header"
                  width="14%">
                  - Reference
                </td>
                {recordValues.map((item, index) => (
                  <td key={`unitReferencesDocuments-${index}-${item.key}`}>
                    {recordValues[index].pocUnitLevel.length > 0 &&
                      recordValues[index].pocUnitLevel.map(
                        (unit, index) =>
                          unit.documentSection && (
                            <DocumentTableCell
                              key={`tablecelldoc-${item.key}--${index}`}
                              documentSection={unit.documentSection}
                              areDocumentsApplicable={changeType(
                                unit.isApplicable
                              )}
                              areDocumentsApplicableComment={unit.comment}
                            />
                          )
                      )}
                  </td>
                ))}
              </tr>
            )}
            <tr>
              <td
                valign="top"
                align="right"
                className="inner-table-header"
                width="14%">
                - Project level:
              </td>
              {recordValues.map((item, index) =>
                item.pocProjectLevel.map(unit => (
                  <td key={`project-${index}-${unit.id}}`}>{unit.comment}</td>
                ))
              )}
            </tr>
            {projectReferencesDocuments.length > 0 && (
              <tr>
                <td
                  valign="top"
                  align="right"
                  className="inner-table-header"
                  width="14%">
                  - Reference
                </td>
                {recordValues.map((item, index) => (
                  <td key={`projectReferencesDocuments-${item.key}-${index}`}>
                    {recordValues[index].pocProjectLevel.length > 0 &&
                      recordValues[index].pocProjectLevel.map(
                        (project, index) =>
                          project.documentSection && (
                            <DocumentTableCell
                              key={`doctablecell-${index}-${item.key}`}
                              documentSection={project.documentSection}
                              areDocumentsApplicable={changeType(
                                project.isApplicable
                              )}
                              areDocumentsApplicableComment={project.comment}
                            />
                          )
                      )}
                  </td>
                ))}
              </tr>
            )}
          </tbody>
        </table>
      )}
      {(recordValues[0].keyCharacteristics.length > 0 ||
        recordValues[1]?.keyCharacteristics.length > 0) && (
        <KeyCharacteristicsInnerTable
          keyCharacteristicsTypes={record.types}
          keyCharacteristicsCountries={recordValues.map(
            record => record.keyCharacteristics
          )}
          minColumns={countriesNum}
        />
      )}
    </>
  )
}

export default ComparisonExpandedRow
