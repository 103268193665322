import { ChangeEvent, Select, SelectOptionData } from '@veracity/vui'
import FormLabel from './FormLabel'
import { useController, Control } from 'react-hook-form'

interface FormSelectProps {
  name: string
  label: string
  options: SelectOptionData[]
  control: Control<any>
  disabled?: boolean
  isMultiple?: boolean
  limit?: number
  labelProps?: any
  selectProps?: any
}

const FormSelect = ({
  name,
  label,
  options,
  control,
  disabled,
  isMultiple,
  limit,
  labelProps,
  selectProps,
}: FormSelectProps) => {
  const {
    field: { onChange, value }
  } = useController({ control, name })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value

    if (isMultiple) {
      const currentValues = value ?? []
      const valueExists = currentValues.includes(newValue)
      const newValues = valueExists
        ? currentValues.filter(
          (currentValue: string | number) => currentValue !== newValue
        )
        : [newValue, ...currentValues]
      onChange(limit ? newValues.slice(0, limit) : newValues)
    } else {
      onChange(newValue)
    }
  }

  let finalOptions = options
  if (label.toLocaleLowerCase().includes('international')) {
    const _internationalOptions = finalOptions.filter(
      item => item.text.includes('EU') || item.text.includes('EN')
    )
    const _countryOptions = finalOptions.filter(
      item => !(item.text.includes('EU') || item.text.includes('EN'))
    )
    finalOptions = [
      { text: 'International', value: 'int', disabled: true },
      ..._internationalOptions,
      { text: 'National', value: 'nat', disabled: true },
      ..._countryOptions
    ]
  }

  return (
    <FormLabel text={label} {...labelProps}>
      <Select
        isMultiple={isMultiple}
        value={finalOptions.findIndex(opt => opt.value === value) === -1
          ? null
          : value
        }
        readOnly={disabled || options.length === 0}
        options={finalOptions}
        onChange={handleChange}
        {...selectProps}>
        {finalOptions.map(option => (
          <Select.Option key={option.value} {...option} />
        ))}
      </Select>
    </FormLabel>
  )
}

export default FormSelect
