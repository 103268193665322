import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { RootState } from '../store'
import { UserRoles } from '../store/slices/user'

export const ProtectedRoute = ({ children }: { children: ReactElement }) => {
  const isLoggedIn = useSelector<RootState, UserRoles>(
    state => UserRoles[state.user.role as keyof typeof UserRoles]
  )

  if (isLoggedIn < 0 || isLoggedIn === undefined) return <Navigate to="/" />

  return children
}
