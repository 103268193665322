import { Box, Heading, Button, Spinner } from '@veracity/vui'
import { useNavigate, useParams } from 'react-router-dom'
import Container from '../../components/styled/Container'
import MdContentContainer from '../Home/CountriesCards/ContentContainer'
import DocumentsList from '../Home/CountriesCards/DocumentsList'
import { useEffect, useState } from 'react'
import { RfgClassificationType } from '../Search/CountryTypeBox'
import { getRfgClassificationTypes } from '../../api/search'
import { InfoBox } from '../Search/CountryTypeBoxInfo'
import { CountryDetailsResult, getCountry } from '../../api/countries'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import './CountryReport.css'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const CountryReportPage = () => {
  const navigate = useNavigate()
  const { countryId } = useParams()

  const [isLoading, setLoading] = useState(true)
  const [countryRfgTypes, setCountryRfgTypes] = useState<
    RfgClassificationType[] | []
  >([])
  const [selectedCountry, setSelectedCountry] = useState<CountryDetailsResult>()

  useEffect(() => {
    Promise.all([
      getCountry(countryId || '').then(setSelectedCountry),
      getRfgClassificationTypes(countryId || '').then(setCountryRfgTypes)
    ]).finally(() => setLoading(false))
  }, [countryId])

  return (
    <Container>
      <Box justifyContent="center" column mt={4} className="displayedText">
        <Box>
          <Button variant="secondaryDark" onClick={() => navigate(-1)}>
            Back
          </Button>
        </Box>
        <Box column>
          <Heading
            size="h3"
            fontWeight={500}
            text={`Report ${selectedCountry?.name}`}
            my={1}
          />
        </Box>
        <Box column mt={3}>
          <Heading
            size="h4"
            fontWeight={500}
            text={`Country intro`}
            my={1}
            borderBottomWidth={1}
            borderBottomColor="black"
          />
          <Box mt={2}>
            <MdContentContainer>
              <ReactMarkdown
                children={selectedCountry ? selectedCountry.intro : ''}
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]}
                className="textStyle"
              />
            </MdContentContainer>
          </Box>
        </Box>
        <Box column mt={4}>
          <Heading
            size="h4"
            fontWeight={500}
            text={`Detailed country info`}
            my={1}
            borderBottomWidth={1}
            borderBottomColor="black"
          />
          <Box mt={2}>
            <MdContentContainer>
              <ReactMarkdown
                children={selectedCountry ? selectedCountry.info : ''}
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]}
                className="textStyle"
              />
            </MdContentContainer>
          </Box>
        </Box>
        <Box mt={4} display="block">
          <Heading
            size="h4"
            fontWeight={500}
            text={`Document table`}
            my={1}
            borderBottomWidth={1}
            borderBottomColor="black"
          />
          <Box mt={2}>
            <DocumentsList documents={selectedCountry?.documents || []} />
          </Box>
        </Box>
        <Box column mt={4}>
          <Heading
            size="h4"
            fontWeight={500}
            text={`Classification thresholds`}
            my={1}
            borderBottomWidth={1}
            borderBottomColor="black"
          />
          <Box mt={2} display="block">
            {isLoading ? (
              <Box mt={4} w="100%" center>
                <Spinner text="Loading countries..." />
              </Box>
            ) : (
              <InfoBox
                countryName={selectedCountry?.name || ''}
                rfgTypes={countryRfgTypes}
              />
            )}
          </Box>
        </Box>

        <Box mt={4} display="block" mb={4}>
          <Heading
            size="h4"
            fontWeight={500}
            text={`Search results note`}
            my={1}
            borderBottomWidth={1}
            borderBottomColor="black"
          />
          <Box mt={2}>
            <MdContentContainer>
              <ReactMarkdown
                children={selectedCountry ? selectedCountry.resultsNote : ''}
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]}
                className="textStyle"
              />
            </MdContentContainer>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default CountryReportPage
