import { Box, Button, SelectOptionData } from "@veracity/vui"
import { ModalDialog } from "./ModalDialog"
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { SearchFormParams } from "../api/search"
import { yupResolver } from '@hookform/resolvers/yup'
import CountryTypeBox from "../pages/Search/CountryTypeBox"
import { useSelector } from "react-redux"
import { RootState } from "../store"
import { mapToSelectOption } from "../utils/dictionary"

export interface AddNewColumnModalProps {
    isOpen: boolean
    onClose: () => void
    onAddColumn: (values: SearchFormParams) => void
}


export const AddNewColumnModal = ({
    isOpen,
    onClose,
    onAddColumn
}: AddNewColumnModalProps) => {

    const countryOptions = useSelector<RootState, SelectOptionData[]>(state =>
        mapToSelectOption(state.dictionaries.countries)
    )

    const schema = yup
        .object({
            items: yup
                .array(
                    yup.object({
                        countryId: yup.string().required(),
                        rfgTypeId: yup.string().required()
                    })
                )
                .required()
        })
        .required()

    const form = useForm<SearchFormParams>({
        defaultValues: {
            items: [{ countryId: undefined, rfgTypeId: undefined }]
        },
        resolver: yupResolver(schema),
        mode: 'onChange'
    })


    const handleAddColumn = (values: SearchFormParams) => {
        onAddColumn(values)
    }

    return (
        <ModalDialog isOpen={isOpen} onClose={onClose} minWidth="30%" minHeight="35%"
            title="Add new column">
            <Box mt={3} w='100%' justifyContent='center'>
                <CountryTypeBox form={form} countryOptions={countryOptions} />
            </Box>
            <Box mt={5} w='100%' justifyContent='end'>
                <Button text="Add" mr={2} onClick={form.handleSubmit(handleAddColumn)}></Button>
                <Button text="Cancel" onClick={onClose}></Button>
            </Box>
        </ModalDialog>
    )
}