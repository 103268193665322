import { Badge, Box } from '@veracity/vui'
import { Compliance, ComplianceApplicableLevels } from '../../api/search'
import { DocumentSectionLink } from '../../components/DocumentSectionLink'
import { PopoverComment } from './PopoverComment'

export interface ComplianceTableCellProps {
  compliance: Compliance
}

export const ComplianceTableCell = ({
  compliance
}: ComplianceTableCellProps) => {
  const getIconType = (type: ComplianceApplicableLevels) => {
    switch (type) {
      case ComplianceApplicableLevels.Yes:
        return 'falCheckCircle'
      case ComplianceApplicableLevels.No:
        return 'falTimesCircle'
      default:
        return ''
    }
  }

  return (
    <Box column gap={1}>
      <Box gap={1}>
        <Badge
          iconLeft={getIconType(compliance.isApplicable)}
          text={compliance.complianceMethod?.name}
          variant="subtleGreen"
        />

        {compliance.comment && (
          <PopoverComment text={compliance.comment}></PopoverComment>
        )}
      </Box>
      <DocumentSectionLink documentSection={compliance.documentSection} />
    </Box>
  )
}
