import { Box, Button, Divider, SelectOptionData } from '@veracity/vui'
import FormSelect from '../../components/form/FormSelect'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { mapToSelectOption } from '../../utils/dictionary'
import { createSearchParams, useNavigate } from 'react-router-dom'

import CountryTypeBox from './CountryTypeBox'
import { SearchFormParams } from '../../api/search'

const SingleCountryForm = () => {
  const countryOptions = useSelector<RootState, SelectOptionData[]>(state =>
    mapToSelectOption(state.dictionaries.countries)
  )
  const technologyOptions = useSelector<RootState, SelectOptionData[]>(state =>
    mapToSelectOption(state.dictionaries.technologies)
  )

  const navigate = useNavigate()

  const schema = yup
    .object({
      technologyId: yup.string().required(),
      items: yup
        .array(
          yup.object({
            countryId: yup.string().required(),
            rfgTypeId: yup.string().required()
          })
        )
        .required()
    })
    .required()

  const form = useForm<SearchFormParams>({
    defaultValues: {
      technologyId: undefined,
      items: [{ countryId: undefined, rfgTypeId: undefined }]
    },
    resolver: yupResolver(schema),
    mode: 'onChange'
  })

  const disabled = !form.formState.isValid

  const onSubmit = (values: SearchFormParams) => {
    const routeValues = {
      countryId: values.items.map((x: { countryId: string }) => x.countryId),
      rfgClassificationTypeId: values.items.map(
        (x: { rfgTypeId: string }) => x.rfgTypeId
      ),
      technologyId: values.technologyId
    }
    const search = '?' + createSearchParams(routeValues).toString()
    navigate({ pathname: '/country-requirements', search })
  }

  return (
    <Box mt={3} mb={3} gap={4} column alignItems="center">
      <Box gap={6} column>
        <FormSelect
          name="technologyId"
          label="Technology"
          options={technologyOptions}
          control={form.control}
          labelProps={{ w: 340 }}
        />
        <Box gap={5}>
          <CountryTypeBox form={form} countryOptions={countryOptions} />
        </Box>
      </Box>
      <Divider w="100%" />
      <Button w={120} disabled={disabled} onClick={form.handleSubmit(onSubmit)}>
        Search
      </Button>
    </Box>
  )
}

export default SingleCountryForm
