import { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useNavigate } from 'react-router-dom'
import {
  Accordion,
  AccordionOnToggleEvent,
  Box,
  Button,
  Card,
  Heading
} from '@veracity/vui'
import DocumentsList from './DocumentsList'
import MdContentContainer from './ContentContainer'
import { CountrySummaryResult } from '../../../api/countries'
import AccStylesOverride from '../../../components/styled/AccStylesOverride'

interface Props {
  header: string
  countries: CountrySummaryResult[]
}
export const CountryItemsAccordion = ({ header, countries }: Props) => {
  const navigate = useNavigate()
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const handleAccordionToggle = (event: AccordionOnToggleEvent): void => {
    const items = selectedIds.filter(p => p !== event.id)

    setSelectedIds(event.collapsed ? items : [...items, event.id.toString()])
  }

  return (
    <Card mt={2} mb={8} p={3} column>
      <Heading
        size="lead"
        fontSize="20px"
        lineHeight="28px"
        weight={600}
        mb={2}>
        {header}
      </Heading>
      <AccStylesOverride>
        <Accordion>
          {countries.map(elem => (
            <Accordion.Item
              key={elem.isO31661Code}
              id={elem.isO31661Code}
              title={elem.name}
              borderBottom="3px solid white"
              className={
                selectedIds.includes(elem.isO31661Code)
                  ? 'acc--is-open'
                  : 'acc--is-closed'
              }
              collapsed={!selectedIds.includes(elem.isO31661Code)}
              onToggle={handleAccordionToggle}
              size="sm">
              <Box column gap={1} w="100%">
                <Box w="100%" justifyContent="flex-end">
                  <Button
                    variant="secondaryDark"
                    mb={1}
                    mx={2}
                    onClick={() =>
                      navigate('/search', { state: { id: elem.id } })
                    }>
                    Search
                  </Button>
                  <Button
                    variant="secondaryDark"
                    mb={1}
                    onClick={() =>
                      navigate(`/${elem.id}/report`, {
                        state: { countriesData: elem }
                      })
                    }>
                    Country report
                  </Button>
                </Box>

                <MdContentContainer>
                  <ReactMarkdown children={elem.intro} />
                  <DocumentsList documents={elem.documents} />
                </MdContentContainer>
              </Box>
            </Accordion.Item>
          ))}
        </Accordion>
      </AccStylesOverride>
    </Card>
  )
}
