import {
  LoggedInHeader,
  LoggedOutHeader,
  Header as VuiHeader,
  HeaderSignIn,
  HeaderAccount,
  List,
  Icon,
  Button,
} from '@veracity/vui'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import TextLogo from '../styled/TextLogo'
import { useNavigate } from 'react-router-dom'

const Header = () => {
  const isLoggedIn = useSelector<RootState, boolean>(
    state => state.user.isLoggedIn
  )
  const displayName = useSelector<RootState, string | undefined>(
    state => state.user.fullName
  )
  const navigate = useNavigate();

  const isOnHomePage: boolean = window.location.pathname === '/';

  return isLoggedIn ? (
    <LoggedInHeader
      logo={<VuiHeader.Logo brand="dnv" url="/" />}
      mainLinks={<TextLogo text="DiGCCe" size="title" mr={1} />}
      account={
        <HeaderAccount
          sections={
            <List isInteractive>
              <List.Divider />
              <a href="/account/signout">
                <List.Item iconLeft="falSignOut" text="Sign out" />
              </a>
            </List>
          }
          userInfo={{ displayName, companyName: 'DNV' }}
        />
      }
      children={!isOnHomePage && <><List.Divider className='hFenrk' />
        <Button onClick={() => navigate('/')} variant='tertiaryDark' size='sm'>
          <Icon name='falHome' />
        </Button>
      </>}
    />
  ) : (
    <LoggedOutHeader
      logo={<VuiHeader.Logo brand="dnv" url="/" />}
      mainLinks={<TextLogo text="DiGCCe" size="title" mr={1} />}
      signIn={<HeaderSignIn url="/account/signin" />}
    />
  )
}

export default Header
