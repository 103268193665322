import axios from './base'
export interface DocumentSection {
  id: string
  name: string
  documentId: string
  documentShortName: string
  page: number
}
export enum ComplianceLevel {
  Unit,
  Project
}
export enum ComplianceApplicableLevels {
  Yes,
  No,
  NotAvailable
}
export interface Compliance {
  id: string
  level: ComplianceLevel
  isApplicable: ComplianceApplicableLevels
  complianceMethod?: ComplianceMethod
  documentSection?: DocumentSection
  comment: string
}

export interface ComplianceMethod {
  id: string
  name: string
  countryId: string
}

export interface KeyCharacteristic {
  id: string
  comment: string
  plotData: string
  keyCharacteristicTypeId: string
}

export interface CountryDocument {
  id: string
  name: string
  shortName: string
  documentType: number
}

export enum DocumentsApplicableLevels {
  Yes,
  No,
  NotAvailable
}
interface CountryRequirementMatrix {
  id: string
  areDocumentsApplicable: DocumentsApplicableLevels
  areDocumentsApplicableComment: string
  documentSections: DocumentSection[]
  compliances: Compliance[]
  condensedSummaries: string[]
  countryRequirementSummaries: string[]
  keyCharacteristics: KeyCharacteristic[]
  countryId: string
  rfgClassificationTypeId: string
}
export interface KeyCharacteristicTypes {
  id: string
  name: string
  tableDataJsonSchema?: string
}

interface SearchResultData {
  requirementId: string
  requirementName: string
  requirementCategory: string
  countryRequirementMatrices: CountryRequirementMatrix[]
  keyCharacteristicTypes: KeyCharacteristicTypes[]
}
interface SearchResultHeader {
  countryId: string
  rfgClassificationTypeId: string
  countryDocuments: CountryDocument[]
}
export interface SearchResultResponse {
  headers: SearchResultHeader[]
  results: SearchResultData[]
}
interface SearchRequestBody {
  technologyId: string
  items: {
    countryId: string
    rfgClassificationTypeId: string
  }[]
  requirementId?: string
}

export interface SearchFormParams {
  technologyId: string
  categoryId?: string
  requirementId?: string
  items: [
    {
      countryId: string
      rfgTypeId: string
    }
  ]
}

export const search = (
  data: SearchRequestBody
): Promise<SearchResultResponse> =>
  axios.post('/api/Search', data).then(response => response.data)

export const getCountries = () =>
  axios.get('/api/Countries').then(response => response.data)

export const getRfgClassificationTypes = (countryId?: string) =>
  countryId
    ? axios
        .get(`/api/Countries/${countryId}/RfgClassificationTypes`)
        .then(response => response.data)
    : axios.get(`/api/RfGClassificationTypes`).then(response => response.data)

export const getTechnologies = () =>
  axios.get('/api/Technologies').then(response => response.data)

export const getDictionaries = () =>
  axios.get('/api/Search/dictionaries').then(response => response.data)
