import styled from 'styled-components'
import MDWrapper from '../../../components/styled/MDWrapper'

const MdContentContainer = styled(MDWrapper)`
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
`

export default MdContentContainer
