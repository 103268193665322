import { Box } from '@veracity/vui'
import styled from 'styled-components'

const Container = styled(Box)<{ maxWidth?: number }>`
  max-width: ${props => props.maxWidth || 1200}px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  .vui-tabsNavBar {
    justify-content: center;
  }

  .vui-tabsNavBar {
    justify-content: center;
  }

  .vui-selectButton:disabled {
    background: #f3f2f2;
    border: none;
    color: #908d89;
  }

  .vui-selectOption[aria-disabled='true'] {
    background: white;
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
  }
  .vui-selectOption[aria-disabled='true'][value='nat'] {
    margin-top: 2em;
  }
`

export default Container
