import * as R from 'ramda'
import {
  DocumentLanguage,
  DocumentStatus,
  DocumentType
} from '../../../api/countries'

const documentStatuses = {
  [DocumentStatus.Draft]: 'Draft',
  [DocumentStatus.Active]: 'Active',
  [DocumentStatus.Inactive]: 'Inactive'
}

const documentLanguages = {
  [DocumentLanguage.Official]: 'Official',
  [DocumentLanguage.UnOfficial]: 'Unofficial',
  [DocumentLanguage.Native]: 'Native'
}

const documentTypes = {
  [DocumentType.GridCodes]: 'Grid Codes',
  [DocumentType.Guidelines]: 'Guidelines',
  [DocumentType.Legislation]: 'Legislation'
}

export const getDocumentTypeName = (type: DocumentType) =>
  documentTypes[type] || 'Unknown'
export const getDocumentStatusName = (status: DocumentStatus) =>
  documentStatuses[status] || 'Unknown'
export const getDocumentLanguageName = (language: DocumentLanguage) =>
  documentLanguages[language] || 'Unknown'

interface WithType {
  documentType: DocumentType
}

export const groupDocumentsByType = <T extends WithType>(documents: T[]) =>
  R.groupBy<T>(R.compose(getDocumentTypeName, doc => doc.documentType))(
    documents
  )
