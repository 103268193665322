import type { RouteObject } from 'react-router-dom'
import HomePage from './pages/Home'
import NotFoundPage from './pages/NotFound'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { BaseLayout } from './components/BaseLayout'
import SearchPage from './pages/Search'
import CountryRequirementsPage from './pages/CountryRequirements'
import { ProtectedRoute } from './components/ProtectedRoute'
import CountryReportPage from './pages/CountryReport'

const routes: RouteObject[] = [
  {
    path: '/',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <HomePage />
      },
      {
        path: '/search',
        element: (
          <ProtectedRoute>
            <SearchPage />
          </ProtectedRoute>
        )
      },
      {
        path: '/country-requirements',
        element: (
          <ProtectedRoute>
            <CountryRequirementsPage />
          </ProtectedRoute>
        )
      },
      {
        path: ':countryId/report',
        element: (
          <ProtectedRoute>
            <CountryReportPage />
          </ProtectedRoute>
        )
      },
      {
        path: '*',
        element: (
          <ProtectedRoute>
            <NotFoundPage />
          </ProtectedRoute>
        )
      }
    ]
  }
]

const router = createBrowserRouter(routes)

const AppRouter = () => <RouterProvider router={router} />

export default AppRouter
