import { Box, Icon, Popover } from '@veracity/vui'

export interface PopoverCommentProps {
  text: string
}

export const PopoverComment = ({ text }: PopoverCommentProps) => (
  <Popover placement="top-end" trigger="mouseenter">
    <Popover.Trigger as={Box} w={80} center>
      <Icon color="blue.80" name="falInfoCircle" size="sm" />
    </Popover.Trigger>
    <Popover.Content bg="prussian.80" color="white" p={2}>
      {text}
    </Popover.Content>
  </Popover>
)
