import styled from 'styled-components'

const CenterAbsolute = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -55px);
`

export default CenterAbsolute
