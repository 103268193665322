import { Box, BoxProps, Heading } from '@veracity/vui'
import { ReactNode } from 'react'

interface FormLabelProps extends BoxProps {
  children: ReactNode
  text: string
}

const FormLabel = ({ children, text, ...props }: FormLabelProps) => (
  <Box column {...props}>
    <label>
      <Heading mb={0.5} size="h6" text={text} />
    </label>
    {children}
  </Box>
)

export default FormLabel
