import { Box, Image, P } from '@veracity/vui'
import { KeyCharacteristic, KeyCharacteristicTypes } from '../../api/search'
import { ImageValidator } from '../../components/ImageValidator'
import { KeyCharacteristicTablePreview } from '../../components/KeyCharacteristicTablePreview'
import { CONFIGURATION } from '../../configuration'

export interface KeyCharacteristicsInnerTableProps {
  keyCharacteristicsCountries: KeyCharacteristic[][]
  keyCharacteristicsTypes: KeyCharacteristicTypes[]
  minColumns?: number
}

export const KeyCharacteristicsInnerTable = ({
  keyCharacteristicsTypes,
  keyCharacteristicsCountries,
  minColumns
}: KeyCharacteristicsInnerTableProps) => (
  <table className="inner-table">
    <tbody>
      <tr>
        <td
          valign="top"
          width="14%"
          className="inner-table-header inner-table-title">
          Key characteristics
        </td>
        {keyCharacteristicsCountries.map((_, index) => (
          <td
            key={index}
            valign="top"
            colSpan={
              minColumns && minColumns > keyCharacteristicsCountries.length
                ? minColumns
                : 1
            }
            width={`${86 / keyCharacteristicsCountries.length}%`}
          />
        ))}
      </tr>
      {keyCharacteristicsTypes.map((keyCharacteristicType, index) => (
        <tr
          key={`${keyCharacteristicType.id}--${index}`}
          style={{ borderTop: '1px solid black' }}>
          <td
            valign="top"
            align="right"
            className="inner-table-header"
            width="14%">
            - {keyCharacteristicType.name}
          </td>
          {keyCharacteristicsCountries.map((countryItem, index) => {
            const keyCharacteristic = countryItem.find(
              x => x.keyCharacteristicTypeId === keyCharacteristicType.id
            )
            return (
              <td valign="top" key={index}>
                <Box gap={1} alignItems="flex-start" wrap overflow="auto">
                  <div>
                    {!keyCharacteristic?.plotData ? (
                      <ImageValidator
                        imageSrc={`${CONFIGURATION.URLS.KEY_CHARACTERISTICS}/${keyCharacteristic?.id}`}>
                        <Image
                          minW={200}
                          maxW={640}
                          src={`${CONFIGURATION.URLS.KEY_CHARACTERISTICS}/${keyCharacteristic?.id}`}
                        />
                      </ImageValidator>
                    ) : (
                      keyCharacteristicType.tableDataJsonSchema && (
                        <KeyCharacteristicTablePreview
                          jsonStringifiedData={keyCharacteristic?.plotData}
                          jsonSchema={keyCharacteristicType.tableDataJsonSchema}
                        />
                      )
                    )}
                    {!!keyCharacteristic?.comment && (
                      <P size="sm">
                        <strong>Caption: </strong>
                        {keyCharacteristic?.comment}
                      </P>
                    )}
                  </div>
                </Box>
              </td>
            )
          })}
          {minColumns && minColumns > keyCharacteristicsCountries.length && (
            <td></td>
          )}
        </tr>
      ))}
    </tbody>
  </table>
)
