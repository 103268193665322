import { Box, Spinner } from '@veracity/vui'
import { useState, useEffect, useMemo } from 'react'

import {
  CountrySummaryResult,
  getCountrySummaries
} from '../../../api/countries'
import { CountryItemsAccordion } from './CountryItemsAccordion'

const INTERNATIONAL_KEYWORDS = ['EU', 'EN']

const CountryCards = () => {
  const [isLoading, setLoading] = useState(true)
  const [countrySummaryResults, setCountrySummaryResults] = useState<
    CountrySummaryResult[]
  >([])

  useEffect(() => {
    setLoading(true)
    getCountrySummaries()
      .then(setCountrySummaryResults)
      .finally(() => setLoading(false))
  }, [])

  const internationalSectionCountries = useMemo(
    () =>
      countrySummaryResults.filter(
        country =>
          INTERNATIONAL_KEYWORDS.some(keyword =>
            country.name.includes(keyword)
          )
      ),
    [countrySummaryResults]
  )

  const otherSectionCountries = useMemo(
    () =>
      countrySummaryResults.filter(
        country =>
          !INTERNATIONAL_KEYWORDS.some(keyword =>
            country.name.includes(keyword)
          )
      ),
    [countrySummaryResults]
  )

  return isLoading ? (
    <Box mt={4} w="100%" center>
      <Spinner text="Loading countries..." />
    </Box>
  ) : (
    <>
      <CountryItemsAccordion
        header="International"
        countries={internationalSectionCountries}
      />
      <CountryItemsAccordion
        header="National"
        countries={otherSectionCountries}
      />
    </>
  )
}

export default CountryCards
