import { createSlice } from '@reduxjs/toolkit'

export enum UserRoles {
  Admin = 99,
  Moderator = 1,
  Viewer = 0,
  Unknown = -1
}

interface UserState {
  isLoggedIn: boolean
  role: string
  fullName?: string
  firstName?: string
  lastName?: string
}

const initialState: UserState = {
  isLoggedIn: false,
  role: '',
  fullName: undefined,
  firstName: undefined,
  lastName: undefined
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      if (payload) {
        state.isLoggedIn = true
        state.fullName = payload.fullName
        state.firstName = payload.firstName
        state.lastName = payload.lastName
        state.role = payload.role
      } else {
        state.isLoggedIn = false
        state.fullName = undefined
        state.role = ''
      }
    }
  }
})

export const { reducer: userReducer, actions: userActions } = userSlice
