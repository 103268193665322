import { PropsWithChildren } from 'react'
import { Icon, Heading, Box, Modal, Spinner } from '@veracity/vui'

export interface ModalDialogProps extends PropsWithChildren {
  isOpen: boolean
  onClose: () => void
  title: string
  inProgress?: boolean
  displayCancel?: boolean
  onSubmit?: () => void,
  minWidth?: string,
  maxWidth?: string,
  minHeight?: string,
  maxHeight?: string,
}

export const ModalDialog = ({
  isOpen,
  onClose,
  title,
  inProgress,
  children,
  minWidth = "50%",
  maxWidth = "60%",
  minHeight = "50%",
  maxHeight = "70%",
}: ModalDialogProps) => (
  <Modal disableBackdropClose isOpen={isOpen} onClose={onClose}>
    <Box
      bg="white"
      borderRadius="md"
      boxShadow="2"
      centerH
      column
      left="50%"
      outline="none"
      p={2}
      position="absolute"
      top='5%'
      transform="translate(-50%,0)"
      minW={minWidth}
      maxW={maxWidth}
      minH={minHeight}
      maxH={maxHeight}
      alignItems="flex-start"
      overflow='hidden'
      overflowY='auto'>
      <Box
        position="absolute"
        right={0}
        top={0}
        borderRadius={20}
        borderStyle={'solid'}
        borderColor="white"
        bg="white"
        borderWidth={5}
        cursor="pointer"
        onClick={onClose}>
        <Icon name="falTimesCircle" size="sm" />
      </Box>

      <Heading size="h2">{title}</Heading>

      {inProgress ? (
        <Box column center w="100%" pt="2em">
          <Spinner />
        </Box>
      ) : (
        children
      )}
    </Box>
  </Modal>
)
