import { Heading, Icon, Link, Table } from '@veracity/vui'
import { CountrySummaryDocumentResult } from '../../../api/countries'
import {
  getDocumentLanguageName,
  getDocumentStatusName,
  getDocumentTypeName
} from './utils'
import { Box } from '@xstyled/styled-components'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { Space } from 'antd'
import { CONFIGURATION } from '../../../configuration'

dayjs.locale('es')

interface DocumentsListProps {
  documents: CountrySummaryDocumentResult[]
}

const DocumentsList = ({ documents }: DocumentsListProps) => {
  if (documents.length === 0) return null

  const formatTime = (data: Date) => dayjs(data).format('MM/YYYY')

  return (
    <Box minWidth="100%">
      <Heading text="Selected documents" size="h6" mt={1} />
      <Table
        mt={2}
        columns={[
          {
            field: 'name',
            isSortable: true,
            title: 'Short Name'
          },
          {
            field: 'documentType',
            isSortable: true,
            title: 'Type'
          },
          {
            field: 'status',
            isSortable: true,
            title: 'Status'
          },
          {
            field: 'language',
            isSortable: true,
            title: 'Language'
          },
          {
            field: 'published',
            isSortable: false,
            title: 'Published'
          },
          {
            field: 'author',
            isSortable: false,
            title: 'Author'
          },
          {
            field: 'comment',
            isSortable: false,
            title: 'Comment'
          },
          {
            field: 'actions',
            title: ''
          }
        ]}
        rows={documents.map(doc => ({
          name: doc.shortName,
          documentType: getDocumentTypeName(doc.documentType),
          status: getDocumentStatusName(doc.status),
          language: getDocumentLanguageName(doc.language),
          published: formatTime(doc.releaseDate),
          author: doc.author,
          comment: doc.comment,
          actions: (
            <Space>
              <Link
                href={`${CONFIGURATION.URLS.DOCUMENT}/${doc.id}`}
                isExternal>
                <Icon size="sm" name="falFilePdf" />
              </Link>
              <Link href={doc.officialUrl} isExternal>
                <Icon size="sm" name="falExternalLink" />
              </Link>
            </Space>
          )
        }))}
      />
    </Box>
  )
}

export default DocumentsList
