import styled from 'styled-components'

const BgContainer = styled.div<{ fullScreen?: boolean }>`
  background-image: url(/assets/Electric-Grid-Cascade.png);
  background-size: cover;
  background-position: center center;
  height: ${props => (props.fullScreen ? '70vh' : 'auto')};
  max-height: 800px;
  width: 100%;
  position: relative;
`

export default BgContainer
