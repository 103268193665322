import axios from './base'

export enum DocumentType {
  GridCodes,
  Guidelines,
  Legislation
}

export enum DocumentStatus {
  Draft = 0,
  Active = 1,
  Inactive = 2
}

export enum DocumentLanguage {
  Official = 0,
  UnOfficial = 1,
  Native = 2
}

export interface CountrySummaryDocumentResult {
  id: string
  name: string
  shortName: string
  documentType: DocumentType
  documentId: string
  officialUrl: string
  type: number
  status: DocumentStatus
  language: DocumentLanguage
  availability: number
  releaseDate: Date
  author: string
  comment: string
}

export interface CountrySummaryResult {
  id: string
  name: string
  intro: string
  isO31661Code: string
  documents: CountrySummaryDocumentResult[]
}

export interface CountryDetailsResult {
  id: string
  name: string
  isoCode: string
  info: string
  intro: string
  resultsNote: string
  documents: CountrySummaryDocumentResult[]
}

export const getCountry = (id: string) =>
  axios
    .get(`/api/countries/${id}`)
    .then(response => response.data as CountryDetailsResult)

export const getCountrySummaries = () =>
  axios
    .get('/api/CountrySummaries')
    .then(response => response.data as CountrySummaryResult[])

export interface RatingsModel {
  rating: number
  technologyId: string
  countriesIds: string[]
}

export const setCountryRatings = (rating: RatingsModel) =>
  axios.post('/api/ratings', rating).then(response => response.data)
