import BaseTable from '../../components/BaseTable'
import { Box, Icon } from '@veracity/vui'
import useResultFilters from './useResultFilters'
import {
  Compliance,
  ComplianceLevel,
  CountryDocument,
  DocumentSection,
  search,
  SearchResultResponse
} from '../../api/search'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { DictionaryEntry } from '../../store/slices/dictionaries'
import MultiCountriesExpandedRow from './MultiCountriesExpandedRow'
import { PopoverCountryDocuments } from './PopoverCountryDocuments'
import { ComplianceTableCell } from './ComplianceTableCell'
import { DocumentSectionLink } from '../../components/DocumentSectionLink'

const MultiCountriesTable = () => {
  const queryParams = useResultFilters()
  const countriesDictionary = useSelector<RootState, DictionaryEntry[]>(
    state => state.dictionaries.countries
  )
  const [searchResult, setSearchResult] = useState<SearchResultResponse>({
    results: [],
    headers: []
  })
  const dataSource = useMemo(() => {
    const rows = searchResult.headers.map(
      ({ countryId, rfgClassificationTypeId }) => {
        const data = searchResult.results[0].countryRequirementMatrices.find(
          mx =>
            mx.countryId === countryId &&
            mx.rfgClassificationTypeId === rfgClassificationTypeId
        )
        const countryName = countriesDictionary.find(
          ({ id }) => countryId === id
        )?.name
        return {
          key: countryId,
          name: countryName,
          coveredBy: {
            documentSections: data?.documentSections || [],
            countryDocuments:
              searchResult.headers.find(
                header => header.countryId === countryId
              )?.countryDocuments || []
          },
          pocUnitLevel: data?.compliances?.filter(
            compliance => compliance.level === ComplianceLevel.Unit
          ),
          pocProjectLevel: data?.compliances?.filter(
            compliance => compliance.level === ComplianceLevel.Project
          ),
          condensedSummaries: data?.condensedSummaries,
          countryRequirementSummaries: data?.countryRequirementSummaries,
          keyCharacteristics: data?.keyCharacteristics
        }
      }
    )

    return rows
  }, [searchResult, countriesDictionary])

  const columns = useMemo(
    () => [
      { title: 'Country', dataIndex: 'name', key: 'name', width: 300 },
      ...searchResult.results.map(
        ({ requirementId, requirementName, keyCharacteristicTypes }) => {
          const key = requirementId
          const title = requirementName

          return {
            title,
            className: `header-${key}`,
            types: keyCharacteristicTypes,
            children: [
              {
                title: 'Covered by',
                dataIndex: 'coveredBy',
                key: 'coveredBy',
                render: ({
                  documentSections,
                  countryDocuments
                }: {
                  documentSections: DocumentSection[]
                  countryDocuments: CountryDocument[]
                }) => (
                  <Box>
                    <Box column flex={5}>
                      {documentSections?.map((docSection, index) => (
                        <DocumentSectionLink
                          documentSection={docSection}
                          key={index}
                        />
                      ))}
                    </Box>
                    <Box flex={1}>
                      <PopoverCountryDocuments
                        countryDocuments={countryDocuments}
                      />
                    </Box>
                  </Box>
                )
              },
              {
                title: 'Proof of Compliance',
                children: [
                  {
                    title: 'Unit level',
                    key: 'pocUnitLevel',
                    align: 'center' as const,
                    dataIndex: 'pocUnitLevel',
                    render: (compliances: Compliance[]) => {
                      if (!compliances) return undefined

                      return compliances.length
                        ? compliances
                            .filter(
                              ({ level }) => level === ComplianceLevel.Unit
                            )
                            .map(compliance => (
                              <ComplianceTableCell compliance={compliance} />
                            ))
                        : ' - '
                    }
                  },
                  {
                    title: 'Project level',
                    key: 'pocProjectLevel',
                    align: 'center' as const,
                    dataIndex: 'pocProjectLevel',
                    render: (compliances: Compliance[]) => {
                      if (!compliances) return undefined
                      return compliances.length
                        ? compliances
                            .filter(
                              ({ level }) => level === ComplianceLevel.Project
                            )
                            .map(compliance => (
                              <ComplianceTableCell compliance={compliance} />
                            ))
                        : ' - '
                    }
                  }
                ]
              }
            ]
          }
        }
      )
    ],
    [searchResult]
  )

  const [searchResultLoading, setSearchResultLoading] = useState(true)

  useEffect(() => {
    search({ ...queryParams }).then(result => {
      setSearchResult(result)
      setSearchResultLoading(false)
    })
  }, [queryParams])

  return (
    <BaseTable
      loading={searchResultLoading}
      dataSource={dataSource}
      columns={columns}
      expandable={{
        rowExpandable: record => !record.isCategory,
        expandedRowRender: record => (
          <MultiCountriesExpandedRow
            record={record}
            headerKey={searchResult.results[0]?.requirementId}
            keyCharacteristicTypes={
              searchResult.results[0].keyCharacteristicTypes // TODO: Check why [0] is used
            }
          />
        ),
        expandRowByClick: true,
        expandedRowClassName: () => 'clear-padding',
        expandIcon: ({ expandable, expanded, onExpand, record }) => (
          <>
            {expandable && (
              <Icon
                onClick={(event: any) => onExpand(record, event)}
                h={12}
                w={12}
                name="falChevronDown"
                rotate={expanded ? 180 : 0}
                transition="transform .3s"
              />
            )}
          </>
        )
      }}
      rowClassName={record => (record.isCategory ? 'category' : '')}
      pagination={false}
    />
  )
}

export default MultiCountriesTable
