import { Card, Icon, Heading, P, Link } from '@veracity/vui'
import CenterAbsolute from '../../components/styled/CenterAbsolute'
import Divider from './Divider'
import Links from './Links'
import Message from './Message'

const NotFoundPage = () => (
  <CenterAbsolute>
    <Card column={false} align="center" py={5} px={8} position="relative">
      <Icon name="falExclamationCircle" scale={6} />
      <Divider />
      <Message>
        <Heading text="Sorry, we couldn't find that page" mb={2} />
        <P
          text="The page you're searching for has either ben moved or the link is
          broken."
        />
      </Message>
      <Links>
        <Link text="Contact support" href="mailto:support@dnv.com" />
        <Link text="Go to homepage" href="/" />
      </Links>
    </Card>
  </CenterAbsolute>
)

export default NotFoundPage
