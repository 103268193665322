import { Link } from '@veracity/vui'
import { DocumentSection } from '../api/search'
import { CONFIGURATION } from '../configuration'

export interface DocumentSectionLinkProps {
  documentSection?: DocumentSection
}

export const DocumentSectionLink = ({
  documentSection
}: DocumentSectionLinkProps) => {
  return !documentSection ? null : (
    <Link
      key={documentSection?.id}
      text={`${documentSection?.name} of ${documentSection?.documentShortName}`}
      href={
          documentSection?.documentId ? `${CONFIGURATION.URLS.DOCUMENT}/${documentSection?.documentId}#page=${documentSection?.page}`
          : undefined
      }
      target="_blank"
      iconLeft="falPaperclip"
      onClick={event => event.stopPropagation()}
    />
  )
}
