import { Rate } from 'antd'
import { setCountryRatings } from '../../api/countries'

interface RateAntdProps {
  technologyId: string
  countriesIds: string[]
}

const RateAntd = ({ technologyId, countriesIds }: RateAntdProps) => {
  return (
    <Rate
      onChange={value =>
        setCountryRatings({
          rating: value,
          technologyId: technologyId,
          countriesIds: countriesIds
        })
      }
    />
  )
}

export default RateAntd
