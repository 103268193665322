import { Accordion, Box } from "@veracity/vui"
import Container from "../../components/styled/Container"
import useResultFilters from "./useResultFilters"
import { useEffect, useState } from "react";
import { CountryDetailsResult, CountrySummaryResult, getCountry, getCountrySummaries } from "../../api/countries";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import './ResultNotes.css';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { Link } from "react-router-dom";


const ResultNotes = () => {

    const { items } = useResultFilters();

    //Regarding for task 468363 - for now lets display result notes for ony one country
    const [selectedCountryId, setSelectedCountryId] = useState<string>(items[0].countryId)
    const [selectedCountry, setSelectedCountry] = useState<CountryDetailsResult>()
    const [countriesData, setCountriesData] = useState<CountrySummaryResult[]>([])
    const [selectedCountryData, setSelectedCountryData] = useState<CountrySummaryResult>()

    const fetchCountry = async () => {
        try {
            const selectedCountry = await getCountry(selectedCountryId);
            setSelectedCountry(selectedCountry);
            const countrySummaries = await getCountrySummaries();
            setCountriesData(
                countrySummaries.filter(
                    item => !(item.name.includes('EU') || item.name.includes('EN'))
                )
            )
            setSelectedCountryData(countrySummaries.find(item => item.id === selectedCountryId))
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchCountry()
    }, [])

    const linkStyle = {
        marginLeft: "0.5rem",
        textDecoration: "underline",
        color: 'blue'
    };

    const title = !!selectedCountry ? <Box className='title'><p>Notes regarding {selectedCountry.name} </p>( see also
        <Link to={`/${selectedCountryId}/report`} state={{ countriesData: selectedCountryData }} style={linkStyle}>country report</Link>)</Box> : '';

    return (
        <Box mb={3}>
            <Container>
                <Box w='100%'>
                    {!!selectedCountry && <Accordion w='100%' mr='auto' ml='auto'>
                        <Accordion.Item id={0}
                            title={title}>

                            <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} className="displayedText" children={selectedCountry.resultsNote} />
                        </Accordion.Item>
                    </Accordion>}
                </Box>
            </Container>
        </Box>
    )
}

export default ResultNotes