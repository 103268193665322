import { Heading } from '@veracity/vui'
import Container from '../../components/styled/Container'
import SingleCountryForm from './SingleCountryForm'


const SearchPage = () => {

  return (
    <Container column maxWidth={1100}>
      <Heading
        mt={10}
        mb={8}
        size="h3"
        fontWeight={600}
        textAlign='center'
        text="Please select the technology, localization and type of search you want to do"
      />
      <SingleCountryForm />
    </Container>
  )
}

export default SearchPage
