import { Box, Icon, List, ListItem, Popover } from '@veracity/vui'
import { CountryDocument } from '../../api/search'

export interface PopoverCountryDocumentsProps {
  countryDocuments: CountryDocument[]
}

export const PopoverCountryDocuments = ({
  countryDocuments
}: PopoverCountryDocumentsProps) => (
  <Popover placement="top-end" trigger="mouseenter">
    <Popover.Trigger as={Box} w={80} center>
      <Icon color="blue.80" name="falInfoCircle" size="sm" />
    </Popover.Trigger>
    <Popover.Content bg="prussian.80" color="white" p={2}>
      <List heading="Country Documents" size="md">
        {countryDocuments.map(document => (
          <ListItem key={document.id}>{document.shortName}</ListItem>
        ))}
      </List>
    </Popover.Content>
  </Popover>
)
