import { Table } from 'antd'
import { TableProps } from 'antd/lib/table'
import styled from 'styled-components'

const StyleWrapper = styled.div`
  .ant-table-thead > tr > th,
  .ant-table-thead > tr > td {
    background-color: hsl(30, 8%, 95%);
    text-align: center;
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
  }

  .ant-table-thead > tr:first-child > th:nth-child(1) {
    border-left: 0px;
  }

  .ant-table-thead > tr:first-child > th:nth-child(2) {
    border-left: 0px;
    text-align: left;
  }

  th.ant-table-cell::before {
    display: none;
  }

  .ant-table-thead > tr > td,
  .ant-table-thead > tr > th {
    font-size: 16px;
    font-weight: 600;
    color: #0f204b;
    border-bottom: 2px solid #0f204b;
  }
`

interface BaseTableProps extends TableProps<any> {}

const BaseTable = (props: BaseTableProps) => {
  return (
    <StyleWrapper>
      <Table {...props} />
    </StyleWrapper>
  )
}

export default BaseTable
