import { Box, P } from '@veracity/vui'
import { RfgClassificationType } from './CountryTypeBox'

interface InfoBoxProps {
  countryName: string
  rfgTypes: RfgClassificationType[]
}

export const InfoBox = ({ countryName, rfgTypes }: InfoBoxProps) => {
  const _listCondition = rfgTypes && rfgTypes?.length > 0

  return (
    <Box direction="column" maxW={'100%'}>
      {_listCondition &&
        rfgTypes.map((rfgType, ind) => (
          <P size="sm" key={ind}>
            <b>{rfgType.name}</b>: {rfgType.description}
          </P>
        ))}
    </Box>
  )
}
